import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import styles from "./AddPost.module.scss";
import { selectIsAuth } from "../../redux/slices/auth";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import { ThemeProvider, createTheme } from "@mui/material";

export const AddPost = () => {
  const { id } = useParams();  // Получаем id поста из параметров URL
  const navigate = useNavigate();
  const isAuth = useSelector(selectIsAuth);
  const [isLoading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const inputFileRef = React.useRef(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0193DE",
      },
      secondary: {
        main: "#0193DE",
      },
    },
  });

  const isEditing = Boolean(id);  // Определяем, редактируем ли пост

  // Получение поста для редактирования
  useEffect(() => {
    if (isEditing) {
      axios.get(`/posts/${id}`).then(({ data }) => {
        setTitle(data.title);
        setText(data.text);
        setSelectedCategory(data.category?._id);  // Устанавливаем выбранную категорию
        setImageUrl(data.imageUrl);
      }).catch((err) => {
        console.warn(err);
        alert("Ошибка при получении поста для редактирования");
      });
    }
  }, [id, isEditing]);

  // Загрузка категорий
  useEffect(() => {
    axios.get("/categories").then(({ data }) => {
      setCategories(data);
    }).catch((err) => {
      console.warn(err);
      alert("Ошибка при загрузке категорий");
    });
  }, []);

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setImageUrl(data.url);
    } catch (err) {
      console.warn(err);
      alert("Ошибка при загрузке файла");
    }
  };

  const onClickRemoveImage = () => {
    setImageUrl("");
  };

  const onChange = useCallback((value) => {
    setText(value);
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);

      const fields = {
        title,
        imageUrl,
        category: selectedCategory,
        text,
      };

      const { data } = isEditing
        ? await axios.patch(`/posts/${id}`, fields)  // Редактирование поста
        : await axios.post("/posts", fields);  // Создание нового поста

      const _id = isEditing ? id : data._id;

      navigate(`/posts/${_id}`);
    } catch (err) {
      console.warn(err);
      alert("Ошибка при создании статьи");
    }
  };

  const options = React.useMemo(
    () => ({
      spellChecker: false,
      maxHeight: "400px",
      autofocus: true,
      placeholder: "Введите текст...",
      status: false,
      autosave: {
        enabled: true,
        delay: 1000,
      },
    }),
    []
  );

  if (!window.localStorage.getItem("token") && !isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <Paper style={{ padding: 30 }}>
      <ThemeProvider theme={theme}>
        <Button onClick={() => inputFileRef.current.click()} color="primary" variant="outlined" size="large">
          Загрузить превью
        </Button>
        <input ref={inputFileRef} type="file" onChange={handleChangeFile} hidden />
        {imageUrl && (
          <>
            <Button variant="contained" color="error" onClick={onClickRemoveImage}>
              Удалить
            </Button>
            <img className={styles.image} src={`http://xn--80ayb.online:4444${imageUrl}`} alt="Uploaded" />
          </>
        )}
        <br />
        <br />
        <TextField
          color="primary"
          classes={{ root: styles.title }}
          variant="standard"
          placeholder="Заголовок статьи..."
          value={title}  // Подставляем заголовок
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
        <Select
          value={selectedCategory}  // Подставляем выбранную категорию
          onChange={(e) => setSelectedCategory(e.target.value)}
          variant="standard"
          displayEmpty
          className={styles.categories}
        >
          <MenuItem value="">
            Выберите категорию
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
        <SimpleMDE
          color="primary"
          className={styles.editor}
          value={text}  // Подставляем текст статьи
          onChange={onChange}
          options={options}
        />
        <div className={styles.buttons}>
          <Button onClick={onSubmit} color="primary" size="large" variant="contained">
            {isEditing ? 'Сохранить' : "Опубликовать"}
          </Button>
          <a href="/">
            <Button size="large">Отмена</Button>
          </a>
        </div>
      </ThemeProvider>
    </Paper>
  );
};
